import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
    CardElement,
    Elements,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { Box, Button } from "@mui/material";
import { t } from "i18next";
import Fetch_data_Api from "../API/Fetch_data_Api";
import { toast } from "react-toastify";
import { useTheme } from "@emotion/react";

const CheckoutForm = () => {

    const stripe = useStripe();
    const elements = useElements({ theme: "night" });

    const theme = useTheme();


    const handleSubmit = async (e) => {
        e.preventDefault();

        const result = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
        });

        if (result.error) {
            toast.error(result.error.message);
        } else {
            
            const date = localStorage.getItem("selectedDate");
            const time = localStorage.getItem("selectedSlot");
            const method = localStorage.getItem("Paymethod");

            const address_id = localStorage.getItem("selectedAddres");
            const address = JSON.parse(address_id);
            

            Fetch_data_Api
                .plaecOrder(method, "awaiting", "", date, time, address.id)
                .then((response) => response)
                .then((result) => {
                    toast.success(result.message);
                    if (result.error === false) {
                        localStorage.removeItem("cart");
                        localStorage.removeItem("CartDetails");
                        localStorage.removeItem("cartItems");
                    }
                }).then((res) => {
                    const order_id = result.data.order_id;
                    Fetch_data_Api
                        .add_transactions(order_id, "pending")
                        .then((response) => response)
                        .then((res) => {
                        });

                })
                .catch((error) => console.log("error", error));
        }

    }

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <Box sx={{ color: "white" }}>
                <CardElement className="mt20 mb20" />
            </Box>

            <Button
                type="submit"
                disabled={!stripe || !elements}
                variant="contained"
                fullWidth
                sx={{ mt: 2, borderRadius: '8px', backgroundColor: theme.palette.background.buttonColor }}

            >
                {t("make_a_payment")}
            </Button>
        </form>
    );
};

function Stripe({ amount }) {
    const key = localStorage.getItem('stripeKey');
    const stripekey = key;


    const stripePromise = loadStripe(stripekey);

    const options = {
        // passing the client secret obtained from the server
        appearance: {
            theme: 'flat',
        }
    };

    return (
        <Box my={5} sx={{ color: "white " }}>
            <Elements stripe={stripePromise} options={options}>
                <CheckoutForm amount={amount} />
            </Elements>
        </Box>

    );
}

export default Stripe;
