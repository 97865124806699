import { Add, ArrowBackIosNewOutlined, CloseRounded } from "@mui/icons-material";
import {
    Backdrop,
    Box,
    Button,
    Divider,
    FormLabel,
    IconButton,
    Radio,
    Typography,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { handleClose, handleOpen } from "../config/config";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { t } from "i18next";
import Calendar from "react-calendar";
import api from "../API/Fetch_data_Api";
import { toast } from "react-toastify";

const ConfirmDateTime = ({ setForm, isSelectSlote }) => {

    function back() {
        handleClose(isSelectSlote);
        handleOpen(setForm);
    }
    const [checked, setchecked] = useState();
    const [timeSlot, setTimeSlot] = useState([]);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const [customTime, setCustomTime] = useState(false);
    const [customTimeValue, setCustomTimeValue] = useState("");
    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));

    useEffect(() => {
        const cart = JSON.parse(localStorage.getItem("cartItems"));
        const partner_id = cart.provider_id;
        const date = dayjs(selectedDate).format("YYYY-MM-DD");

        api
            .get_available_slot(partner_id, date)
            .then((response) => {
                setTimeSlot(response.data.all_slots);
            })
            .catch((error) => console.log("error", error));
    }, [selectedDate]);

    const theme = useTheme();
    const customSelectedTime = localStorage.getItem("selectedSlot");
    const selectedCalendarDate = localStorage.getItem("selectedDate");

    const advanceBookingDays = JSON.parse(
        localStorage.getItem("cartItems")
    ).advance_booking_days;
    const disableDateAfter = dayjs().add(advanceBookingDays, "day");

    const shouldDisableDate = (date) => {
        const today = dayjs().startOf("day"); // Get the start of today
        return date.isBefore(today) || date.isAfter(disableDateAfter);
    };

    const handleChange = (event, slot) => {
        setchecked(slot);
        setCustomTimeValue(event);
        localStorage.setItem("selectedSlot", event.time);
        setSelectedTimeSlot(event.time);
    };

    const customTimeSelected = () => {
        handleOpen(setCustomTime);
    };

    const handleCustomTime = () => {
        localStorage.setItem("selectedSlot", customTimeValue); // Store formatted time
        handleClose(setCustomTime);
    };

    const handleTimeChange = (selectedTime) => {
        const formattedTime = selectedTime.format("HH:mm:ss");
        setCustomTimeValue(formattedTime); // Format time
    };

    const name = localStorage.getItem("cartItems");
    const CompanyName = JSON.parse(name);

    const OpenNext = () => {
        const date = localStorage.getItem("selectedDate");


        if (date === null) {
            return toast.error("Please select Date.");
        }
        console.log(customSelectedTime);
        if (selectedTimeSlot === null || customSelectedTime === null) {
            return toast.error("Please select Time From available slots");
        }

        handleClose(isSelectSlote);
        handleOpen(setForm);
    };

    const getTimeOfDay = (time) => {
        const hours = parseInt(time.split(':')[0]);

        if (hours >= 0 && hours < 6) {
            return "Night";
        } else if (hours < 12) {
            return "Morning";
        } else if (hours < 14) {
            return "Noon";
        } else if (hours < 18) {
            return "Afternoon";
        } else if (hours < 20) {
            return "Evening";
        } else if (hours < 24) {
            return "Night";
        } else {
            return "Midnight";
        }
    }




    return (
        <Box>
            <Box mt={1} mb={1} fontWeight={"bolder"} display={"flex"} alignItems={"center"}>
                <IconButton onClick={back} sx={{ fontSize: "28px" }} fontWeight={"bolder"}>
                    <ArrowBackIosNewOutlined />
                </IconButton>
                <Typography fontWeight={"bolder"} variant="h6">
                    {t("from")} {CompanyName.company_name}
                </Typography>
            </Box>
            <Divider />

            <Box
                mt={2}
                mb={2}
                p={2}
                borderRadius={3}
                overflow={"auto"}
                display={"block"}
            >
                <FormLabel >{t("select_date")}</FormLabel>
                <br />
                <br />
                <Box sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                    <Typography
                        p={2}
                        className="select-calendar-heading"
                        sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
                    >
                        {t("preferred_date")}
                    </Typography>
                </Box>
                <Box display={"flex"} justifyContent={"center"}>
                    <Calendar
                        value={
                            selectedCalendarDate ? selectedCalendarDate : dayjs(selectedDate)
                        }
                        onChange={(newValue) => {
                            setSelectedDate(dayjs(newValue).format("YYYY/MM/DD"));
                            localStorage.setItem(
                                "selectedDate",
                                dayjs(newValue).format("YYYY-MM-DD")
                            ); // Convert to string
                        }}
                        calendarType="US" // Set the calendar type to display day names
                        prev2Label={null} // Hide the "previous year" navigation label
                        next2Label={null} // Hide the "next year" navigation label
                        tileDisabled={({ date, view }) => shouldDisableDate(dayjs(date))}
                    />
                </Box>
            </Box>
            <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                p={2}
            >
                <Typography>{t("select_time")}</Typography>
                <Box>
                    <Button onClick={customTimeSelected} sx={{ textTransform: "none" }}>
                        {customSelectedTime
                            ? "Selected Time: " + customSelectedTime
                            : <> <Add /> {t("custom_time")}</>
                        }
                    </Button>
                </Box>
            </Box>

            <Backdrop open={customTime}>
                <Box
                    display={"block"}
                    sx={{
                        background: theme.palette.background.box,
                        p: 3,
                        borderRadius: 3,
                    }}
                >
                    <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        mb={1}
                    >
                        <Typography>{t("select_time")}</Typography>
                        <IconButton onClick={() => handleClose(setCustomTime)}>
                            <CloseRounded />
                        </IconButton>
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label="Select Time"
                            onChange={handleTimeChange} // Call the function when time is changed
                        />
                    </LocalizationProvider>
                    <br />
                    <Button size="small" onClick={handleCustomTime}>
                        {t("submit")}
                    </Button>
                </Box>
            </Backdrop>

            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
                    gap: 2,
                    p: 2,
                }}
            >
                {timeSlot && timeSlot.length !== 0 ? (
                    timeSlot.map((slot, index) => (
                        <Box
                            key={slot.time}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px solid",
                                borderRadius: "15px",
                                p: 1,
                                backgroundColor: slot.is_available === 0 ? "gray" : "",
                            }}
                        >
                            <Radio
                                checked={index === checked}
                                onChange={() => handleChange(slot, index)}
                                value={index}
                                name="radio-buttons"
                                inputProps={{ "aria-label": slot.time }}
                                disabled={slot.is_available === 0}
                                sx={{ p: "2px" }}
                            />
                            <Divider sx={{ width: "100%", }} />
                            <Box pt={"5px"} textAlign={"center"}>
                                <Typography variant="subtitle2" >{getTimeOfDay(slot.time)}</Typography>
                                <Typography variant="body2">{slot.time}</Typography>
                            </Box>

                        </Box>
                    ))
                ) : (
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        ml={30}
                        alignItems={"center"}
                        textAlign={"center"}
                    >
                        <Box>
                            <Box>
                                <img
                                    src={"/images/no-booking.png"}
                                    className="max-h-100"
                                    alt="no time slot"
                                />
                            </Box>
                            <Typography>{t("no_slot_found")}</Typography>
                        </Box>
                    </Box>
                )}
            </Box>

            <Box p={2}>
                <Button variant="contained" fullWidth onClick={() => OpenNext()}>
                    {t("continue")}
                </Button>
            </Box>
        </Box>
    );
};

export default ConfirmDateTime;
