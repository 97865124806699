/* eslint eqeqeq: 0 */

import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
    Box,
    Breadcrumbs,
    Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Skeleton,
    TextField,
    useTheme,
    Link
} from "@mui/material";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import Layout from "../../layout/Layout";
import { LineSkeleton, PartnerSkeleton } from "./Skeletons";
import Partner from "./Partner";
import api from "../../../API/Fetch_data_Api";
import { PersonSearch } from "@mui/icons-material";

// provider page and we got particular provider from reusable partner Section
export default function Provider() {
    const [provider, setProvider] = useState([]);
    const [category, setCategory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    const [selectedCategory, setSelectedCategory] = useState(0);
    const [selectedOrder, setSelectedOrder] = useState("asc");
    const [searchPartner, setSearchPartner] = useState(null);
    const [searchCount, setSearchCount] = useState(0);


    const searchList = [
        {
            value: 'asc',
            label: 'Ascending',
        },
        {
            value: 'desc',
            label: 'Descending',
        },
    ];


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    useEffect(() => {
        api
            .get_providers()
            .then((response) => {
                setProvider(response.data)
                setSearchCount(response.total)
            })
            .then((response) => setIsLoading(true))
            .catch((error) => console.log(error));

        api
            .get_Api_Category()
            .then((response) => {
                const categories = response.data.map(e => ({
                    name: e.name, id: e.id
                }));
                setCategory(categories);
            });

    }, []);




    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    const handleOrderChange = (e) => {
        setSelectedOrder(e.target.value);
    };
    const handleSearch = (e) => {
        api
            .get_providers("", searchPartner, selectedCategory, selectedOrder)
            .then((response) => {
                setProvider(response.data)
                setSearchCount(response.total);
            })
            .catch((error) => console.log(error));
    }




    return (
        <Box
            display={"flex"}
            gridColumn={3}
            flexWrap={"wrap"}
            m={0}
        >
            {isLoading ? (
                <Box display={"flex"} sx={{ flexDirection: { xs: "column", md: "row" }, gap: { xs: 2, md: 1 } }} width={"100%"} mb={2}
                    alignContent={"center"} >
                    <Box>
                        <Typography variant="h6">
                            {t("search_filter")}
                        </Typography>

                    </Box>

                    <Box sx={{ width: { xs: '100%', md: "25%" } }}>
                        <TextField id="outlined-basic"
                            onChange={e => setSearchPartner(e.target.value)}
                            fullWidth size="small" label="Search Provider" placeholder="Search Provider" variant="outlined" />
                    </Box>

                    <Box sx={{ width: { xs: '100%', md: "25%" } }}>
                        <FormControl sx={{ width: "100%" }}>
                            <InputLabel id="demo-multiple-name-label">Select Category</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                size="small"
                                onChange={handleCategoryChange}
                                defaultValue={selectedCategory}
                                input={<OutlinedInput label="Select  Category" />}
                                MenuProps={MenuProps}
                            >
                                <MenuItem
                                    key={0}
                                    value={0}
                                >
                                    Select Category
                                </MenuItem>
                                {category.map((opt) => (
                                    <MenuItem
                                        key={opt.id}
                                        value={opt.id}
                                    >
                                        {opt.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{ width: { xs: '100%', md: "25%" } }}>
                        <FormControl sx={{ width: "100%" }}>
                            <InputLabel id="demo-multiple-name-label">Sort By</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                defaultValue={selectedOrder}
                                size="small"
                                onChange={handleOrderChange}
                                input={<OutlinedInput label="Sort By" />}
                                MenuProps={MenuProps}
                            >
                                {searchList.map((opt) => (
                                    <MenuItem
                                        key={opt.value}
                                        value={opt.value}
                                    >
                                        {opt.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box>
                        <Button
                            startIcon={< PersonSearch />}
                            variant="contained"
                            onClick={handleSearch}
                        >
                            {t('search')}
                        </Button>
                    </Box>

                </Box>) :
                (<Box>
                    <LineSkeleton />
                </Box>)
            }

            {isLoading ? (
                <Box display={"flex"} sx={{ justifyContent: { xs: "space-evenly", md: "space-between" } }}>
                    <Grid container maxWidth={"100%"} spacing={3} sx={{ alignItems: "center", justifyItems: "center" }}>
                        {provider.map((response) => {
                            return (
                                <Grid maxWidth={"380px"} key={response.id} width={"100%"} item 
                                xs={12} md={searchCount === 1 ? 12 : searchCount == 2 ? 6 : 4}>
                                    <Partner partner={response} />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            ) : (
                <Grid container spacing={2}>
                    <Grid
                        item
                        lg={12}
                        display={"flex"}
                        flexWrap={"wrap"}
                        justifyContent={"space-evenly"}
                        gap={2}
                    >
                        <>
                            <PartnerSkeleton />
                            <PartnerSkeleton />
                            <PartnerSkeleton />

                            <PartnerSkeleton />
                            <PartnerSkeleton />
                            <PartnerSkeleton />

                            <PartnerSkeleton />
                            <PartnerSkeleton />
                            <PartnerSkeleton />
                        </>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}

export const MySkeleton = () => {
    return <Skeleton height={500} sx={{ width: { sx: 200, md: 345 } }} variant="rectangular"></Skeleton>;
};

export const SpecificProvider = () => {
    const [provider, setProvider] = useState([]);
    const [isLoading, setIsLoading] = useState(false);



    const param = useParams();
    const { id } = param;

    const get_provider = () => {
        api
            .get_providers(id)
            .then((response) => setProvider(response.data))
            .then((response) => setIsLoading(true))
            .catch((error) => console.log("error", error));
    };

    const { name } = param;
    const formattedName = name
        .replace(/-/g, " ")
        .replace(/\b\w/g, (match) => match.toUpperCase());

    const company_name = localStorage.getItem("Company");
    document.title = `${formattedName} | ${company_name}`;

    useEffect(() => {
        get_provider();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const theme = useTheme();
    return (
        <Layout>
            <Box
                bgcolor={theme.palette.background.heading}
                paddingTop={"15px"}
                paddingBottom={"15px"}
                mb={"20px"}
            >
                <Container maxWidth="lg">
                    <Breadcrumbs separator="|" aria-label="breadcrumb" className="mt-1 mb-1 breadcrumb">
                        <Link
                            href={"/"}
                            className="breadrumb"
                            style={{
                                color: theme.palette.color.breadcrum,
                            }}
                            underline="none"
                        >
                            {t("home")}
                        </Link>
                        <Link
                            href={"/categories"}
                            className="breadrumb"
                            underline="none"
                            style={{
                                color: theme.palette.color.breadcrum,
                            }}
                        >
                            {t("sub_categories")}
                        </Link>
                        <Typography color="text.primary">
                            {t(`${formattedName}`)}
                        </Typography>
                    </Breadcrumbs>
                    <Typography variant="h4" gutterBottom>
                        <strong>{formattedName} Providers</strong>
                    </Typography>
                </Container>
            </Box>
            <Container>
                <Box
                    display={"flex"}
                    gridColumn={3}
                    flexWrap={"wrap"}
                    gap={"22px"}
                    justifyContent={"space-around"}
                    p={2}
                    sx={{
                        background: theme.palette.background.box,
                    }}
                >
                    {isLoading ? (
                        <>
                            {provider.length > 0 ? (
                                provider.map((response) => {
                                    return (
                                        <>
                                            <Partner partner={response} />
                                        </>
                                    );
                                })
                            ) : (
                                <>
                                    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                                        <img
                                            src={"/images/no-provider.png"}
                                            alt="There is no providers"
                                            width={"100%"}
                                            height={"100%"}
                                        />

                                        <Typography variant="body1">
                                            {t("no_provider")}
                                        </Typography>
                                    </Box>
                                </>
                            )}
                        </>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid
                                item
                                lg={12}
                                display={"flex"}
                                flexWrap={"wrap"}
                                justifyContent={"space-evenly"}
                                gap={2}
                                mt={5}
                            >
                                <MySkeleton />
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </Container>
        </Layout>
    );
};
