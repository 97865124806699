import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    Divider,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { handleClose, handleOpen } from "../config/config";
import { toast } from "react-toastify";
import { t } from "i18next";
import api from "../API/Fetch_data_Api";
import { useDispatch, useSelector } from "react-redux";
import { setAddress } from "../redux/UserAddress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@emotion/react";

const UpdateDrawer = ({ openForm, closeMap }) => {
    const [locationName, setLocationName] = useState(
        localStorage.getItem("addLocationName")
    );
    let addressEdit = JSON.parse(localStorage.getItem("addressEdit"));

    const [loading, setLoading] = useState(false);

    const userAddress = useSelector((state) => state.UserAddress).address;
    const dispatch = useDispatch();

    const EditableAddress = localStorage.getItem("EditableAddress");
    const EditableCity = localStorage.getItem("edit-city");
    const Addresses = JSON.parse(EditableAddress);

    const [selectedButton, setSelectedButton] = useState(Addresses.type);
    const [isDefault, setIsDefault] = useState(false);

    const [editApartment, setEditApartment] = useState(null);
    const [editCity, setEditCity] = useState(null);
    const [editMobile, setEditMobile] = useState(null);

    useEffect(() => {
        setEditApartment(Addresses.area);
        setEditCity(EditableCity !== null ? EditableCity : addressEdit.city);
        setEditMobile(Addresses.alternate_mobile);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCheckboxChange = (event) => {
        setIsDefault(event.target.checked);
    };

    const handleButtonClick = (label) => {
        setSelectedButton(label);
    };
    const buttonStyles = (label) => ({
        backgroundColor: selectedButton.toLowerCase() === label.toLowerCase() ? "#0277FA" : "transparent",
        color: selectedButton.toLowerCase() === label ? "white" : "black",
    });

    // console.log(  Addresses.id);

    const AddAddress = () => {
        setLoading(true);
        const lat = localStorage.getItem("addLat");
        const lng = localStorage.getItem("addLong");


        api
            .AddAddress(
                Addresses.id,
                editMobile,
                locationName,
                0,
                editCity,
                lat,
                lng,
                editApartment,
                selectedButton,
                91,
                "",
                "",
                "",
                isDefault,
                ""
            )
            .then((result) => {
                setLoading(false);
                if (result.error === true) {
                    for (var key of Object.keys(result.message)) {
                        if (key === "address")
                            toast.error("Address is required");
                        if (key === "city_name")
                            toast.error("City Name is required");
                        if (key === "area")
                            toast.error("Area/Appartment Name is required");
                        if (key === "mobile")
                            toast.error("Mobile Number is required");
                    }
                } else {
                    toast.success(result.message);

                    const addressData = userAddress.map(item => {
                        if (item.id === Addresses.id) {
                            return {
                                ...item,
                                address: locationName, // Update the address or other properties here
                                area: editApartment, // Update the address or other properties here
                                city_name: editCity, // Update the address or other properties here
                                city_id: editCity, // Update the address or other properties here
                                mobile: editMobile, // Update the address or other properties here
                            };
                        }
                        return item;
                    });
                    dispatch(setAddress(addressData));
                    handleClose(openForm);
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
        // handleClose(openForm);
    };

    function back() {
        handleClose(openForm);
        handleOpen(closeMap);
    }

    const handleMobileChang = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, '');
        // Limit the input to a maximum of 16 characters
        if (numericValue.length <= 16 || numericValue === '') {
            setEditMobile(numericValue);
        }
    };

    const theme = useTheme();
    return (
        <div>
            <Box mt={1} mb={1} display={"flex"} alignItems={"center"}>
                <IconButton onClick={back}>
                    <ArrowBackIosNewOutlined />
                </IconButton>
                <h3>{t("complete_address")}</h3>
            </Box>
            <Divider />
            <Box p={2}>
                <Box>
                    <Typography>{t("house_flat")}</Typography>
                    <TextField
                        placeholder="Enter House no"
                        fullWidth
                        className="address-form"
                        value={locationName}
                        sx={{
                            mb: 2,
                            backgroundColor: theme.palette.background.input,
                        }}
                        onChange={(e) => setLocationName(e.target.value)}
                    />
                    <Typography>{t("appartment_road")}</Typography>
                    <TextField
                        id="apartment"
                        placeholder="Enter Road Name"
                        fullWidth
                        value={editApartment}
                        sx={{
                            mb: 2,
                            backgroundColor: theme.palette.background.input,
                        }}
                        onChange={(e) => setEditApartment(e.target.value)}
                        className="address-form"
                    />
                    <Typography>{t("city")}</Typography>
                    <TextField
                        id="city"
                        placeholder="Enter City Name"
                        value={editCity}
                        sx={{
                            mb: 2,
                            backgroundColor: theme.palette.background.input,
                        }}
                        onChange={(e) => setEditCity(e.target.value)}
                        fullWidth
                        className="address-form"
                    />
                    <Typography>{t("mobile")}</Typography>
                    <TextField
                        id="mobile"
                        placeholder="Enter Mobile Number"
                        fullWidth
                        className="address-form"
                        value={editMobile}
                        sx={{
                            mb: 2,
                            backgroundColor: theme.palette.background.input,
                        }}
                        defaultValue={editMobile}
                        onChange={handleMobileChang}
                        inputProps={{
                            pattern: "[0-9]*", // Allow only numeric input
                            inputMode: "numeric", // Display numeric keyboard on mobile devices
                        }}
                    />
                </Box>

                <Box display={"flex"} gap={3}>

                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                        <Button
                            onClick={() => handleButtonClick("Home")}
                            style={buttonStyles("Home")}
                            sx={{ borderRadius: "10px", borderColor: "gray" }}
                        >
                            <Typography variant="body1" color={theme.palette.color.navLink} >
                                {t("home")}
                            </Typography>
                        </Button>
                    </ButtonGroup>
                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                        <Button
                            onClick={() => handleButtonClick("Office")}
                            style={buttonStyles("Office")}
                            sx={{ borderRadius: "10px", borderColor: "gray" }}
                        >
                            <Typography variant="body1" color={theme.palette.color.navLink} >
                                {t("office")}
                            </Typography>
                        </Button>
                    </ButtonGroup>

                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                        <Button
                            onClick={() => handleButtonClick("Other")}
                            style={buttonStyles("Other")}
                            sx={{ borderRadius: "10px", borderColor: "gray" }}
                        >
                            <Typography variant="body1" color={theme.palette.color.navLink} >
                                {t("other")}
                            </Typography>
                        </Button>
                    </ButtonGroup>

                </Box>



                <Box alignItems={"center"} display={"flex"}>
                    <Checkbox checked={isDefault} onChange={handleCheckboxChange} />
                    <Typography>{t("default_address")}</Typography>
                </Box>

                <Button
                    variant="contained"
                    fullWidth
                    className="address-form"
                    onClick={() => AddAddress()}
                    disabled={loading === true ? true : false}
                >
                    {loading === true ?
                        < FontAwesomeIcon icon={faSpinner} spin />
                        : ""}
                    {t("continue")}
                </Button>
            </Box>
        </div>
    );
};

export default UpdateDrawer;
