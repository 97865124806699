/* eslint eqeqeq: 0 */
import { ArrowBackIosNewOutlined, ConfirmationNumber, Place } from "@mui/icons-material";
import {
    Box,
    Button,
    Divider,
    FormControlLabel,
    IconButton,
    Paper,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    tableCellClasses,
} from "@mui/material";
import { PaystackButton } from "react-paystack";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useEffect, useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { handleClose, handleOpen } from "../config/config";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { t } from "i18next";
import { toast } from "react-toastify";
import api from "../API/Fetch_data_Api";
import { useSelector, useDispatch } from "react-redux";
import Stripe from "../PaymentGateways/Stripe";
import { setPromoCode } from "../redux/Promocode";
import { useTheme } from "@emotion/react";

//closedrawer
const BookingInfoDrawer = ({
    setForm,
    setPromo,
    setBooking,
}) => {
    const dispatch = useDispatch();
    // for fetching cart details
    const [rows, setRows] = useState([]);
    const [amount, setAmount] = useState();
    const [visitingCharges, setVisitingCharges] = useState();
    const [subTotal, setSubTotal] = useState();
    const [coupn, setCoupn] = useState([]);
    const [addressInfo, setAddressInfo] = useState();

    const [payOnService, setPayOnService] = useState();
    const isDeliverHome = localStorage.getItem("DeliveryMethod");

    // we need this in razorpay order place api
    const [orderId, setOrderId] = useState();
    const [cod, setCod] = useState(false);
    const theme = useTheme();

    const handleCOD = () => {
        setCod(!cod);
    };

    const promo = useSelector((state) => state.Promocode);
    const codes = promo.promocode;
    // open and close drawer
    function back() {
        handleClose(setBooking);
        handleOpen(setForm);
    }

    //table row styling
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    //table cell styling
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const currency_symbol = localStorage.getItem("currencySymbol");


    //for fetching cart information when drawer open
    useEffect(() => {
        const cartInfo = localStorage.getItem("cartItems");
        const cart = JSON.parse(cartInfo);

        if (isDeliverHome === "shop") {
            setAmount(cart.sub_total);
        } else {
            setAmount(cart.overall_amount);
        }
        setSubTotal(cart.sub_total);
        setRows(cart.data);
        setVisitingCharges(cart.visiting_charges);
        setPayOnService(cart.is_pay_later_allowed);

        const appliedPromo = localStorage.getItem("selectedPromo");
        if (appliedPromo) {
            const promo = JSON.parse(appliedPromo);
            setCoupn(promo);

        } else {
            setCoupn(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // to open promocode drawer
    function openPromo() {
        handleClose(setBooking);
        handleOpen(setPromo);
    }

    //delivery method and other stuff

    const selectedDate = localStorage.getItem("selectedDate");
    const selectedTime = localStorage.getItem("selectedSlot");
    const selectedAddress = localStorage.getItem("selectedAddres");

    useEffect(() => {
        if (selectedAddress) {
            setAddressInfo(JSON.parse(selectedAddress));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //to remove coupn
    function removeCopun(id) {
        const promos = codes.filter(e => (e.id !== id))
        dispatch(setPromoCode(promos));
        localStorage.removeItem('promocode');


        const isPromoAvailable = localStorage.getItem("selectedPromo");
        if (isPromoAvailable) {
            localStorage.setItem("selectedPromo", "");
            toast.success("Coupn Removed");
            setCoupn();
        } else toast.info("No Coupn Selected");
    }

    // ==========payment integrations==============
    //when user place order
    function plaecorder() {
        const date = localStorage.getItem("selectedDate");
        const time = localStorage.getItem("selectedSlot");
        const method = localStorage.getItem("Paymethod");

        let address_id = localStorage.getItem("selectedAddres");
        let address = ""
        if (isDeliverHome === "Home") {
            address = address_id !== null || address_id === "" ? JSON.parse(address_id) : [];
        }



        api
            .plaecOrder(method, "awaiting", "", date, time, isDeliverHome === "Home" ? 0 : 1, address?.id)
            .then((response) => response)
            .then((result) => {
                toast.success(result.message);
                if (result.error === false) {
                    localStorage.removeItem("cart");
                    localStorage.removeItem("CartDetails");
                    localStorage.removeItem("cartItems");
                    localStorage.removeItem("selectedSlot");
                    localStorage.removeItem("selectedDate");
                    const order_id = result.data.order_id;
                    setOrderId(order_id)
                    api
                        .add_transactions(order_id, "success")
                        .then((response) => response)
                        .then((res) => {

                            window.location.href = "/";
                        });
                }
            })
            .catch((error) => console.log("error", error));
    }

    //we set payment integration setting into our localstorage and from that we fetch data

    const [Key, setStripekey] = useState("");

    const [StripeStatus, setStripeStatus] = useState(false);
    const [razorStatus, setRazorStatus] = useState(false);
    const [paypalStatus, setPaypalStatus] = useState(false);
    const [paystackStatus, setPaystackStatus] = useState(false);
    const [payment_type, setPaymentType] = useState(payOnService === 1 ? "pay_on_service" : "pay_now");

    useEffect(() => {
        api.get_settings().then((response) => {

            const StripeStatus =
                response.data.payment_gateways_settings.stripe_status;
            const RazorStatus =
                response.data.payment_gateways_settings.razorpayApiStatus;
            const PayStackStatuss =
                response.data.payment_gateways_settings.paystack_status;

            if (StripeStatus === "enable") {
                const stripeKey =
                    response.data.payment_gateways_settings.stripe_publishable_key;
                const clientSecret = response.data.payment_gateways_settings.stripe_secret_key;
                localStorage.setItem("stripeKey", stripeKey);
                localStorage.setItem("clientSecret", clientSecret);
                setStripekey(stripeKey);
                setStripeStatus(true);
            } else if (RazorStatus === "enable") {
                const RazorKey = response.data.payment_gateways_settings.razorpay_key;
                setStripekey(RazorKey);
                setRazorStatus(true);
            } else if (PayStackStatuss === "enable") {
                const PayStackKey =
                    response.data.payment_gateways_settings.paystack_key;
                setStripekey(PayStackKey);
                setPaystackStatus(true);
            } else {
                setStripekey("");
                setPaypalStatus(true);
            }
        });
    }, []);

    //stripe config
    // const StripeKey = JSON.parse(keys).stripe_publishable_key;
    // const stripePromise = loadStripe(Key);
    function handlePaymentSuccess(response) {
        // Handle payment success logic here
        localStorage.removeItem("cart");
        localStorage.removeItem("CartDetails");

        // You can perform additional actions, such as updating the UI or making API calls.
    }

    //paystack config
    const componentProps = {
        Key,
        currency: "GHS",
        email: "xyz@2gmail.com",
        onSuccess: handlePaymentSuccess,
    };

    const currency_code = localStorage.getItem("currencyCode");

    //paypal config
    const paypalOptions = {
        "client-id": "test",
        currency: currency_code,
        intent: "capture",
    };

    //razorpay config
    function createRazorOrder() {
        api
            .createRazorOrder(orderId)
            .then((response) => response)
            .then((result) => {

                if (result.error_code === null) {
                    localStorage.removeItem("cart");
                    localStorage.removeItem("CartDetails");
                }
            }).then(ex => {
                // window.location.reload();
            })
            .catch((error) => console.log("error", error));
    }

    const handlePayment = (amount) => {
        localStorage.setItem('Paymethod', 'razorpay');
        const options = {
            key: Key,
            amount: (parseInt(amount) * 100),
            currency: currency_code,
            name: "eDemand",
            description: "Payment for Your Product",
            handler: function (response) {
                plaecorder();
                createRazorOrder();
                if (response.razorpay_payment_id) {
                    localStorage.removeItem("cart");
                    localStorage.removeItem("CartDetails");
                    localStorage.removeItem("");
                }
            },
            theme: {
                color: "#F37254",
            },
        };
        window.Razorpay.open(options);
    };

    const cancleCod = () => {
        setCod(false);
    };


    // Handle the payment failure callback
    const handleFailure = (response) => {

        // You can handle payment failure here, such as displaying an error message to the user
    };

    return (
        <Box display={"block"} width={"80"}>
            <Box mt={1} mb={1} display={"flex"} alignItems={"center"}>
                <IconButton onClick={back}>
                    <ArrowBackIosNewOutlined />
                </IconButton>
                <h3>{t("booking_info")}</h3>
            </Box>
            <Divider />
            <Box mt={2} p={2}>
                <Box border={"1px solid gray"} borderRadius={2}>
                    <Box display={"flex"} p={1} gap={2} alignItems={"center"}>
                        <AccessTimeIcon className="icon" sx={{ fontWeight: "thin" }} fontSize="large" />
                        <Box >
                            <Typography variant="caption" fontSize={14}>
                                {t("booking_date")}
                            </Typography>
                            <Typography fontWeight={"bold"}>
                                {dayjs(selectedDate).format("DD/MM/YYYY")}, {selectedTime}
                            </Typography>
                        </Box>
                    </Box>
                    {isDeliverHome === "Home" ? (
                        <>
                            <Divider />
                            <Box display={"flex"} p={1} gap={2} alignItems={"center"}>
                                <Place className="icon" fontSize="large" />
                                <Box>
                                    <Typography color={"gray"} fontSize={14}>
                                        {t("your_booking_address")}
                                    </Typography>
                                    <Typography fontWeight={"bold"}>
                                        {addressInfo ? addressInfo.address : ""}
                                    </Typography>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        ""
                    )}
                </Box>

                <Box sx={{ my: 5, }}>
                    <Button
                        variant="contained"
                        fullWidth
                        className="promocode-btn"
                        mt={2}
                        border={"1px solid"}
                        sx={{
                            textTransform: "none",
                            borderRadius: "10px",
                            backgroundColor: theme.palette.background.buttonColor
                        }}
                        p={2}
                        onClick={openPromo}
                        borderRadius={"8px"}
                    >
                        {t("aplly_promo")}
                    </Button>
                </Box>

                {(codes !== null && codes.length > 0) ?

                    <Box my={5} px={2} py={2} sx={{ backgroundColor: "#343F53", borderRadius: "8px" }} >

                        <Box display={"flex"} flexDirection={"column"}>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Box display={"flex"} gap={2}>
                                    <ConfirmationNumber sx={{ color: "white" }} />
                                    <Typography color={"white"} fontWeight={"400"} variant="subtitle2">
                                        Use Coupns
                                    </Typography>
                                </Box>
                                <Typography variant="body1" color={"white"}>
                                    {coupn !== undefined && coupn !== null
                                        ?
                                        <Typography variant="subtitle2" color={"white"}>
                                            {coupn.discount} &nbsp;
                                            {coupn.discount_type === "percentage" ? "%" : currency_symbol}
                                        </Typography>
                                        : "Coupons"}
                                </Typography>
                            </Box>
                        </Box>

                        {codes.map((e) => {
                            return (
                                <Box display={"flex"} flexDirection={"column"} borderRadius={"8px"}>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Box display={"flex"} ml={5}>
                                            <Typography variant="subtitle2" color={"white"} fontWeight={"bold"}>
                                                {e !== undefined && e !== null
                                                    ? e.promo_code
                                                    : "Coupons"}
                                            </Typography>
                                        </Box>
                                        <Button
                                            sx={{ textTransform: "none" }}
                                            variant="text"
                                            color="error"
                                            size="small"
                                            ml={"auto"}
                                            onClick={a => removeCopun(e.id)}>
                                            <Typography variant="caption" fontWeight={"bold"}>
                                                {t("remove")}
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            )
                        })}

                    </Box>
                    : ""
                }

                <Box mt={2}>
                    <Typography variant="h6">{t("payment_mode")}</Typography>
                    <Box mt={2}>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={payment_type}
                            onChange={e => setPaymentType(e.target.checked)}
                            name="radio-buttons-group"
                        >
                            {paypalStatus ? (
                                <>
                                    <FormControlLabel
                                        value="Pay now"
                                        control={<Radio />}
                                        label="Pay now"
                                        onClick={cancleCod}
                                        className="mt-1"
                                    />
                                    <Typography mt={"-10px"} ml={4} mb={1} color={"gray"}>
                                        {t("pay_online")}
                                    </Typography>
                                </>
                            ) : (
                                ""
                            )}
                            {payOnService == "0" ?
                                ""
                                : <>
                                    <FormControlLabel
                                        value="pay_on_service"
                                        control={<Radio />}
                                        onChange={handleCOD}
                                        label="Pay on Service"
                                    />
                                    <Typography ml={4} mt={"-10px"} mb={1} color={"gray"}>
                                        {t("pay_service_home")}
                                    </Typography>

                                    <Divider />
                                </>}

                            <FormControlLabel
                                value="pay_now"
                                control={<Radio />}
                                onChange={handleCOD}
                                label="Pay Now"
                            />
                            <Typography ml={4} mt={"-10px"} mb={1} color={"gray"}>
                                {t("pay_now")}
                            </Typography>
                            <Divider />

                        </RadioGroup>
                    </Box>
                </Box>

                {/* Table that contain information about our cart  */}
                <TableContainer className="add-address-btn" component={Paper} sx={{ mt: 5 }}>
                    <Table className="booking-info-table" aria-label="customized table">
                        <TableBody>
                            {rows.map((row) => (
                                <>
                                    <StyledTableRow key={row.name}>
                                        <StyledTableCell component="th" scope="row">
                                            {row.servic_details.title}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {row.qty}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {currency_symbol} {row.servic_details.price_with_tax}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </>
                            ))}

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    {t("sub_total")}
                                </StyledTableCell>
                                <StyledTableCell align="right"></StyledTableCell>
                                <StyledTableCell align="right">{currency_symbol} {subTotal}</StyledTableCell>
                            </StyledTableRow>
                            {isDeliverHome === "Home" ? (
                                <>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            {t("visiting_charges")}
                                        </StyledTableCell>
                                        <StyledTableCell align="right"></StyledTableCell>
                                        <StyledTableCell align="right">{currency_symbol} {visitingCharges}</StyledTableCell>
                                    </StyledTableRow>
                                </>
                            ) : ""}

                            {coupn ? (
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                        {t("promocode")}
                                    </StyledTableCell>
                                    <StyledTableCell align="right"></StyledTableCell>
                                    <StyledTableCell align="right">
                                        - {coupn.discount} {coupn.discount_type == "percentage" ? "%" : currency_symbol}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ) : (
                                ""
                            )}
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    <Typography variant="body1" fontWeight={"bold"}>
                                        {t("total_amount")}
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="right"></StyledTableCell>
                                <StyledTableCell align="right">
                                    <Typography variant="body1" fontWeight={"bold"} color={theme.palette.primary.main}>
                                        {currency_symbol}
                                        {coupn !== null && coupn !== undefined ?
                                            codes !== null && coupn?.discount_type == "percentage" ? ((amount * coupn.discount) / 100) : (coupn.discount_type != "percentage" ? (amount - coupn.discount)
                                                : amount) : parseFloat(parseFloat(amount))
                                        }
                                    </Typography>

                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* at a time only one single payent gateway is enable */}
                {/* payment method integrations  */}
                {cod === true ? (
                    <Box mt={2}>
                        <Button
                            variant="outlined"
                            fullWidth
                            className="place-order-btn"
                            onClick={() => {
                                localStorage.setItem("Paymethod", "cod");
                                plaecorder();
                            }}
                        >
                            {t("place_order")}
                        </Button>
                    </Box>
                ) : (
                    <Box mt={2}>
                        {/* Render StripeCheckout only when StripeKey is available */}
                        {Key && StripeStatus === true ? (
                            <Stripe amount={coupn !== null && coupn !== undefined ?
                                codes !== null && coupn?.discount_type == "percentage" ? ((amount * coupn.discount) / 100) : (coupn.discount_type != "percentage" ? (amount - coupn.discount)
                                    : amount) : parseFloat(parseFloat(amount))} ></Stripe>
                        ) : null}

                        {/* if paystack is enable  */}
                        {paystackStatus ? (
                            <PaystackButton className="myPaystrack" {...componentProps}
                                onSuccess={plaecorder()}
                                onClose={handleFailure}
                            >
                                <Button
                                    className="place-order-btn"
                                    fullWidth
                                    variant="outlined"
                                >
                                    {t("continue")}
                                </Button>
                            </PaystackButton>
                        ) : (
                            ""
                        )}
                        {paypalStatus ? (
                            <PayPalScriptProvider options={paypalOptions}>
                                <PayPalButtons
                                    style={{ layout: "vertical" }}
                                    createOrder={(data, actions) => {
                                        return actions.order.create({
                                            purchase_units: [
                                                {
                                                    amount: {
                                                        value: coupn !== null && coupn !== undefined ?
                                                            codes !== null && coupn?.discount_type == "percentage" ? ((amount * coupn.discount) / 100) : (coupn.discount_type != "percentage" ? (amount - coupn.discount)
                                                                : amount) : parseFloat(parseFloat(amount)), // Pass the amount here
                                                    },
                                                },
                                            ],
                                        });
                                    }}
                                    onApprove={() => {
                                        plaecorder();
                                    }}
                                    onError={(e) => toast.error(e.message)}
                                />
                            </PayPalScriptProvider>
                        ) : (
                            ""
                        )}

                        {/* ifrazorpay is enable */}
                        <Box mt={2}>
                            {razorStatus ? (
                                <Button
                                    variant="outlined"
                                    className="place-order-btn"
                                    fullWidth
                                    sx={{ textTransform: 'none' }}
                                    onClick={e => handlePayment(
                                        coupn !== null && coupn !== undefined ?
                                            codes !== null && coupn?.discount_type == "percentage" ? ((amount * coupn.discount) / 100) : (coupn.discount_type != "percentage" ? (amount - coupn.discount)
                                                : amount) : parseFloat(parseFloat(amount))

                                    )}
                                >
                                    {t("pay_with_razorpay")}
                                </Button>
                            ) : (
                                ""
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default BookingInfoDrawer;
