import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import {
    Box,
    Button,
    Divider,
    IconButton,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { DynamicAddress } from "../Components/Reusable/Profile/Address";
import { handleClose, handleOpen } from "../config/config";
import { toast } from "react-toastify";
import { t } from "i18next";
import api from "../API/Fetch_data_Api";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

const AddressDrawer = ({
    setForm,
    isSelectedSlote,
    MyFun,
    setCart,
    setBooking,
}) => {
    const isselectedDate = localStorage.getItem("selectedDate");
    const isselectedTime = localStorage.getItem("selectedSlot");
    const [selectedAddress, setSelectedAddress] = useState(null);
    const isDeliverHome = localStorage.getItem("DeliveryMethod");
    const [store, setStore] = useState(0);
    const [door, setDoor] = useState(0);

    const location = useSelector((state) => state.location);
    const cart = useSelector((state) => state.cart);
    const provider_id = cart.cartItems[0].servic_details.partner_id;



    useEffect(() => {
        api.get_providers(provider_id).then(res => {

            setStore(res.data[0]?.at_store);
            setDoor(res.data[0]?.at_doorstep);
        }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // check slot and if error is false than only drawer should open otherwise it show error message
    function checkSlot() {
        const cart = JSON.parse(localStorage.getItem("cartItems"));
        const partner_id = cart.provider_id;
        const selectedDate = localStorage.getItem("selectedDate");
        const selectedTime = localStorage.getItem("selectedSlot");
        const selectedAddress = localStorage.getItem("selectedAddres");
        const DeliveryMethod = localStorage.getItem("DeliveryMethod");

        api
            .checkSlots(partner_id, selectedDate, selectedTime)
            .then((response) => {
                if (response.error === false) {
                    handleClose(setForm);
                    handleOpen(setBooking);
                    //if address or doorstep selected than only provider check availablility at that particular location
                    if (selectedAddress && DeliveryMethod === "Home") {
                        providerAvailable(location.lat, location.lng);
                    }
                } else toast.error(response.message);
            })
            .catch((error) => console.log(error));
    }

    // call providerAvailable before open next drawer...
    const providerAvailable = () => {
        const address = localStorage.getItem("selectedAddres");
        //check provider availability and checkout process should be 1 before placing order
        api
            .providerAvailable(address.lattitude, address.longitude, 1)
            .then((result) => {
                if (result.error === false) toast.success(result.message);
                else toast.error(result.message);
            });
    };

    function ContinueClicked() {
        if (!isselectedDate || !isselectedTime) {
            toast.error("Please select date, time before continuing.");
            return;
        }
        if (isDeliverHome === "Home") {
            if (!selectedAddress) {
                toast.error("Please select an address before continuing.");
                return;
            }
        }
        checkSlot();
    }

    const OpenNext = () => {
        handleClose(setForm);
        handleOpen(isSelectedSlote);
    };

    function back() {
        handleClose(setForm);
        handleOpen(setCart);
    }

    const name = localStorage.getItem("cartItems");
    const CompanyName = JSON.parse(name);

    function handleSelectMethodHome() {
        localStorage.setItem("DeliveryMethod", "Home");
    }
    function handleSelectMethodShop() {
        localStorage.setItem("DeliveryMethod", "shop");
    }

    const [view, setView] = React.useState("");

    const handleChange = (event, nextView) => {
        setView(nextView);
        localStorage.setItem("SelectedView", nextView); // Store the selected view
    };
    const theme = useTheme();

    return (
        <div className="overflow-hidden">
            <Box mt={1} mb={1} display={"flex"} sx={{ fontWeight: "bold" }} alignItems={"center"}>
                <IconButton onClick={back}>
                    <ArrowBackIosNewOutlined fontSize="large" />
                </IconButton>
                <h3>
                    {t("from")} {CompanyName.company_name}
                </h3>
            </Box>
            <Divider />
            <Box py={4} px={'8px'}>
                <Typography fontSize={16}>{t("choose_location")}</Typography>
                <ToggleButtonGroup
                    orientation="vertical"
                    value={view}
                    exclusive
                    fullWidth
                    onChange={handleChange}
                >
                    {door === "1" ? (
                        <ToggleButton
                            value="list"
                            aria-label="list"
                            onClick={handleSelectMethodHome}
                        >
                            <div>
                                <Typography>{t("at_door")}</Typography>
                                <Typography
                                    variant="body2"
                                    color={"gray"}
                                    textTransform={"none"}
                                >
                                    {t("door_details")}
                                </Typography>
                            </div>
                        </ToggleButton>
                    ) : (
                        ""
                    )}
                    {store === "1" ? (
                        <ToggleButton
                            value="module"
                            aria-label="module"
                            onClick={handleSelectMethodShop}
                        >
                            <div>
                                <Typography>{t("at_store")}</Typography>
                                <Typography
                                    variant="body2"
                                    color={"gray"}
                                    textTransform={"gray"}
                                >
                                    {t("store_details")}
                                </Typography>
                            </div>
                        </ToggleButton>
                    ) : (
                        ""
                    )}
                </ToggleButtonGroup>
            </Box>

            <Box display={"block"} px={'8px'} py={1}>
                <Box display={"flex"} justifyContent={'space-between'} alignItems={"center"} gap={3}>
                    <Box width={"100%"}>
                        <Typography fontSize={16}>{t("select_date")}</Typography>
                        <TextField
                            id="date"
                            type="date"
                            fullWidth
                            sx={{ borderRadius: "8px" }}
                            value={isselectedDate ? isselectedDate : ""}
                            disabled
                        />
                    </Box>

                    <Box width={"100%"}>
                        <Typography fontSize={16}>{t("select_time")}</Typography>
                        <TextField
                            id="time"
                            type="time"
                            fullWidth
                            value={isselectedTime ? isselectedTime : ""}
                            disabled
                        />
                    </Box>
                </Box>


                <Box mt={2}>
                    <Typography fontSize={16}>{t("select_date_time")}</Typography>
                    <Button
                        className="drawer-button"
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: theme.palette.background.buttonColor,
                            borderRadius: "var( --global-border-radius)",
                            '&:hover': {
                                backgroundColor: theme.palette.background.buttonColor,
                            },
                        }}
                        onClick={() => OpenNext()}
                    >
                        {t("select_time")}
                    </Button>
                </Box>

            </Box>

            {isDeliverHome === "Home" ? (
                <>
                    <Box display={"block"} >
                        <Typography px={'8px'} fontSize={18}>{t("your_address")}</Typography>
                        <DynamicAddress
                            onSelectAddress={(address) => {
                                setSelectedAddress(address);
                            }}
                        />
                        <Box px={'8px'} mb={2}>
                            <Button
                                variant="outlined"
                                fullWidth
                                className="drawer-button-address"
                                onClick={MyFun}
                            >
                                {t("add_address_button")}
                            </Button>
                        </Box>
                    </Box>
                </>
            ) : (
                ""
            )}
            <Box px={'8px'}>
                <Typography fontSize={16}>{t("provider_instruction")}</Typography>
                <TextField fullWidth />
            </Box>

            <Box px={'8px'} pt={2} mb={2}>
                <Button variant="contained"
                    sx={{
                        backgroundColor: theme.palette.background.buttonColor,
                        padding: 1,
                        borderRadius: "var( --global-border-radius)",
                        '&:hover': {
                            backgroundColor: theme.palette.background.buttonColor,
                        },
                    }}
                    fullWidth onClick={() => ContinueClicked()}>
                    {t("continue")}
                </Button>
            </Box>
        </div>
    );
};

export default AddressDrawer;
