import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardMedia, Divider, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../API/Fetch_data_Api";
import { handleClose, handleOpen } from "../config/config";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { t } from "i18next";
import { PromoSkeleton, ServiceSkeleton } from "../Components/Reusable/Sections/Skeletons";
import { setPromoCode } from "../redux/Promocode";
import { useSelector, useDispatch } from "react-redux";


const Promocode = ({ setBooking, setPromo }) => {
    const [isPromo, setIsPromo] = useState([]);
    const [isLoading, setIsloading] = useState(true);

    const cart = JSON.parse(localStorage.getItem("cartItems"));
    const partner_id = cart.provider_id;

    const dispatch = useDispatch();
    const promo = useSelector((state) => state.Promocode);
    const code = promo.promocode;

    //fetching promocodes
    useEffect(() => {
        api
            .Promocode(partner_id)
            .then((result) => {
                setIsPromo(result.data);
                setIsloading(false);
            })
            .catch((error) => console.log("error", error));
    }, []);

    function back() {
        handleClose(setPromo);
        handleOpen(setBooking);
    }

    // validation of promocode
    function applyPromo(promo) {
        api
            .ValidatePromocode(
                partner_id,
                promo.promo_code,
                cart.overall_amount
            )
            .then((result) => {
                dispatch(setPromoCode(result.data));

                if (result.error === true) toast.info(result.message);
                else {
                    toast.success(result.message);
                    localStorage.setItem("selectedPromo", JSON.stringify(promo));
                }
            })
            .catch((error) => console.log("error", error));
    }

    return (
        <div>
            <Box mt={1} mb={1} display={"flex"} alignItems={"center"}>
                <IconButton onClick={back}>
                    <ArrowBackIosNewOutlined fontSize="larges" />
                </IconButton>
                <Typography fontWeight={"bold"} fontSize={24}>{t("promocode")}</Typography>
            </Box>
            <Divider />
            <Box p={2} >
                {isLoading === false ?
                    isPromo && isPromo.length > 0 ? (
                        isPromo.map((promo) => {
                            return (
                                <Card>
                                    <Card sx={{ display: 'flex', gap: 3, alignItems: "center" }} >
                                        <CardMedia
                                            component="img"
                                            sx={{ width: 151 }}
                                            image={promo.image}
                                            alt="Live from space album cover"
                                        />
                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                                            <CardContent sx={{ flex: '1 0 auto', p: 0, pb: 0, width: "100%" }}>
                                                <Box display={"flex"} justifyContent={"space-between"}>
                                                    <Box >
                                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                                            {promo.promo_code}
                                                        </Typography>
                                                        <Typography component="div" variant="h6">
                                                            {promo.discount_type == "percentage" ? "%" : "$"} {promo.discount} OFF
                                                        </Typography>
                                                    </Box>
                                                    {code != null &&
                                                        code.length > 0
                                                        ?
                                                        code.map((e) => {
                                                            if (e.id === promo.id) {
                                                                return (<Box mr={2}>
                                                                    <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        disabled
                                                                    >
                                                                        {t('applied')}
                                                                    </Button>
                                                                </Box>)
                                                            } else {
                                                                return (
                                                                    <Box mr={2}>
                                                                        <Button
                                                                            variant="contained"
                                                                            size="small"
                                                                            onClick={() => applyPromo(promo)}
                                                                        >
                                                                            {t('apply')}
                                                                        </Button>
                                                                    </Box>
                                                                )
                                                            }
                                                        })
                                                        :
                                                        <Box mr={2}>
                                                            <Button
                                                                variant="contained"
                                                                size="small"
                                                                onClick={() => applyPromo(promo)}
                                                            >
                                                                {t('apply')}
                                                            </Button>
                                                        </Box>
                                                    }
                                                </Box>
                                            </CardContent>
                                        </Box>
                                    </Card>
                                    <Box my={2} sx={{ textTransform: "capitalize", lineHeight: 2 }}>
                                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                            {t("min_order_amount")} &nbsp; {promo.minimum_order_amount}
                                        </Typography>
                                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                            {t("max_discount")}: &nbsp;
                                            {promo.max_discount_amount}
                                        </Typography>
                                        <Typography variant="subtitle2" >
                                            {t("offer_valid")} &nbsp; &nbsp;
                                            {dayjs(promo.start_date).format("YYYY/MM/DD")} &nbsp; to &nbsp;
                                            {dayjs(promo.end_date).format("YYYY/MM/DD")}
                                        </Typography>
                                    </Box>
                                </Card>
                            );
                        })
                    ) : (
                        <>
                            <Box
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                textAlign={"center"}
                                sx={{ flexDirection: { xs: "column", md: "row" } }}
                            >
                                <Box>
                                    <Box height={"100%"} width={"100%"}>
                                        <img
                                            src={"/images/no-booking.png"}
                                            alt="no time slot"
                                        />
                                    </Box>
                                    <Typography>{t("no_promo")}</Typography>
                                </Box>
                            </Box>
                        </>
                    ) :
                    <PromoSkeleton />
                }
            </Box>
        </div>
    );
};

export default Promocode;
