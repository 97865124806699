/* eslint eqeqeq: 0 */
import {
    AppBar,
    Box,
    Button,
    Toolbar,
    styled,
    IconButton,
    Drawer,
    Container,
    Avatar,
    Typography,
    Badge,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { handleForce } from "../../redux/Login"

import "intl-tel-input/build/css/intlTelInput.css";
import { NavLink, json, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import NavigationLinks, {
    PhoneNavigationLinks,
} from "./Navigation/NavigationLinks";
import Authentication from "./Navigation/Authentication";
import EdemandSetting from "./Navigation/EdemandSetting";
import { t } from "i18next";
import { ToastContainer, toast } from "react-toastify";
import Cart from "../Reusable/Sections/CartItem";
import { DARK_LOGO, LIGHT_LOGO, handleClose, handleOpen } from "../../config/config";
import EmptyCart from "./Navigation/EmptyCart";
import AddressDrawer from "../../Drawers/AddressDrawer";
import ConfirmDateTime from "../../Drawers/ConfirmDataTime";
import AddAddressMap from "../../Drawers/AddAddressMap";
import AddAddressForm from "../../Drawers/AddAddressForm";
import BookingInfoDrawer from "../../Drawers/BookingInfoDrawer";
import Promocode from "../../Drawers/Promocode";
import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';

//for creating styled logo
const StyledToolBar = styled(Toolbar)({
    display: "flex",
    color: "blue",
    maxWidth: "lg",
    justifyContent: "space-between",
});
const Navigation = ({ check, changeLight, changeDark }) => {
    const [open, setOpen] = React.useState(false);
    const [openSetting, setOpenSetting] = useState(false);
    const cartDetails = useSelector((state) => state.cart);


    const loggedIn = localStorage.getItem('isLoggedIn');
    const profile = useSelector((state) => state.UserData);
    const isLog = useSelector((state) => state.authentication)?.isLoggedIn;
    const forsedClose = useSelector((state) => state.Login)?.isForceClose;
    let web_settings = useSelector((state) => state.Settings)?.settings;
    web_settings = web_settings?.web_settings;

    // const [login, isLogin] = React.useState(false);
    const [login, isLogin] = React.useState(

        isLog === false && forsedClose == false ? true : false

    );




    const [cart, setCart] = useState(false);
    const [form, setForm] = useState(false);
    const [selectSlote, isSelectedSlote] = useState(false);
    const [addAddress, setAddAddress] = useState(false);
    const [addressFrom, setAddressForm] = useState(false);
    const [booking, setBooking] = useState(false);
    const [promo, setPromo] = useState(false);

    const navigate = useNavigate();

    const handleOpenSetting = () => {
        setOpenSetting(true);
    };

    const handleCloseSetting = () => {
        setOpenSetting(false);
    };

    const handleOpenLogin = () => {
        isLogin(true);
    };
    const mode = localStorage.getItem("darkMode");


    // mode change toggle
    const storedTheme = localStorage.getItem("SelectedTheme");
    const [view, setView] = React.useState(storedTheme);
    const theme = useTheme();

    function BookingDetails() {
        handleClose(setForm);
        handleOpen(setBooking);
    }

    function ContinueClicked() {
        handleClose(setCart);
        handleOpen(setForm);
    }

    function OpenMapDrawer() {
        handleOpen(setAddAddress);
        handleClose(setForm);
    }

    function CompleteAddress() {
        handleClose(setAddAddress);
        handleOpen(setAddressForm);
    }

    function BookingDrawer() {
        handleClose(isSelectedSlote);
        handleOpen(setBooking);
    }

    const [islogined, setIsloggedIn] = useState(
        localStorage.getItem("isLoggedIn")
    );
    const profilePicture = profile.profile.image;

    const handleCartOpening = () => {
        if (islogined == "true") {
            handleOpen(setCart)
        } else {
            toast.error("You must be logged in to access this page.");
        }
    }
    let items = localStorage.getItem("cartItems");
    items = items !== undefined || items !== null ? JSON.parse(items) : "";
    return (
        <Box zIndex={1000}>
            <AppBar
                style={{
                    backgroundColor: theme.palette.background.box,
                    boxShadow: "none",
                }}
                sx={{
                    paddingRight: "0px !important"
                }}
            >
                <Container>
                    <Box>
                        <StyledToolBar className="pr-0" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", pr: 0 }}>
                            <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                MaxWidth={"100%"}
                            >
                                <div>
                                    <IconButton
                                        color={theme.palette.text.primary}
                                        aria-label="open-drawer"
                                        edge="start"
                                        sx={{
                                            display: { lg: "none" },
                                            ml: { xs: "-20px" },
                                            mr: { xs: "25px" },
                                        }}
                                        onClick={() => setOpen(true)}
                                    >
                                        <MenuIcon color={theme.palette.text.primary} />
                                    </IconButton>
                                </div>
                                {/* #Phone Menu  */}
                                {/* Set logo and burger menu in one side */}
                                <div>

                                    {/* # PC Navigation  */}
                                    {/* logo  */}
                                    <NavLink
                                        className={"logo"}
                                        style={{
                                            color: theme.palette.color.logo,
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%"
                                        }}
                                        to="/"
                                    >
                                        <Box
                                            component={"img"}
                                            src={mode === "true" ? web_settings?.web_logo : web_settings?.web_logo}
                                            alt="logo"
                                            sx={{ width: { xs: "30%", md: "40%" } }}
                                        >

                                        </Box>
                                    </NavLink>
                                </div>

                                {/* Navigation Links  */}
                            </Box>
                            <NavigationLinks />

                            {/* #Navigation Button functionality */}


                            <Authentication
                                login={login}
                                isLogin={isLogin}
                                setIsloggedIn={setIsloggedIn}
                            />

                            <Box display={"flex"} justifyContent={"end"} width={{ xs: "100%", md: "50%" }} alignItems={"center"}>
                                {islogined === "true" ? (
                                    <IconButton
                                        id="logined_user"
                                        onClick={() => navigate("/profile")}
                                    >
                                        <Avatar
                                            sx={{ height: "30px", width: "30px", color: "white" }}
                                            src={profilePicture ? profilePicture : ""}
                                        />
                                    </IconButton>
                                ) : (
                                    <Button
                                        id="sign_in"
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        sx={{ mx: 1, my: 1 }}
                                        startIcon={<AccountCircleIcon />}
                                        onClick={handleOpenLogin}
                                    >
                                        {t("sign_in")}
                                    </Button>
                                )}
                                {/* =================Authentication=================  */}

                                <div style={{
                                    position: "relative",
                                    height: "30px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    width: "35px",
                                }}>
                                    <IconButton
                                        aria-label="cart"
                                        onClick={() => handleCartOpening()}
                                    >
                                        <Badge badgeContent={cartDetails.cartItems != undefined && loggedIn == "true" ? cartDetails.cartItems.length : 0} color="primary">
                                            <ShoppingCartOutlinedIcon />
                                        </Badge>
                                    </IconButton>
                                </div>


                                {/* eDemand Setting drawer */}
                                <Box display={{ sm: "none", xs: "none", md: "flex" }} justifyContent={"center"} alignItems={"center"}>
                                    <IconButton onClick={handleOpenSetting} >
                                        <SettingsOutlinedIcon />
                                    </IconButton>
                                </Box>


                                {/* cart section that display cart items */}
                                {islogined === "" ? (
                                    <Drawer
                                        anchor="right"
                                        open={cart}
                                        onClose={() => handleClose(setCart)}
                                    >
                                        <EmptyCart />
                                    </Drawer>
                                ) : (
                                    <Drawer
                                        anchor="right"
                                        open={cart}
                                        onClose={() => handleClose(setCart)}
                                        sx={{
                                            display: { xs: "block", sm: "block" },
                                            "& .MuiDrawer-paper": {
                                                boxSizing: "border-box",
                                                width: { md: 580, xs: "100%" },
                                            },
                                        }}
                                    >
                                        {/* set this box width 400 if needed */}
                                        <Box
                                            display={"flex"}
                                            width={"100%"}
                                            alignItems={"center"}
                                            gap={1}
                                            my={1}>
                                            <IconButton onClick={() => handleClose(setCart)}>
                                                <ArrowBackIosNewOutlined fontSize="large" />
                                            </IconButton>

                                            <Typography
                                                variant="h6"
                                                textAlign={"center"}
                                                fontWeight={"bold"}
                                            >
                                                {cartDetails?.cartItems?.length > 0 ?
                                                    <>
                                                        {t("from")} {cartDetails?.cartItems[0]?.servic_details?.partner_name}
                                                    </>
                                                    :
                                                    t("cart")
                                                }
                                            </Typography>
                                        </Box>
                                        <Cart continueClicked={ContinueClicked} />
                                    </Drawer>
                                )}

                                {/* Select Date and time and based on that We send request to check available slot */}
                                <Drawer
                                    open={form}
                                    anchor="right"
                                    sx={{
                                        display: { xs: "block", sm: "block" },
                                        "& .MuiDrawer-paper": {
                                            boxSizing: "border-box",
                                            width: { md: 740, xs: "100%" },
                                        },
                                    }}
                                >
                                    <Box>
                                        <AddressDrawer
                                            setCart={setCart}
                                            setForm={setForm}
                                            isSelectedSlote={isSelectedSlote}
                                            continueFun={BookingDetails}
                                            MyFun={OpenMapDrawer}
                                            setBooking={setBooking}
                                        />
                                    </Box>
                                </Drawer>

                                {/* In this drawer we provide them clanader and list all available time slotes and on select another request send to same api */}
                                <Drawer
                                    open={selectSlote}
                                    anchor="right"
                                    sx={{
                                        display: { xs: "block", sm: "block" },
                                        "& .MuiDrawer-paper": {
                                            boxSizing: "border-box",
                                            width: { md: 580, xs: "100%" },
                                        },
                                    }}
                                >
                                    <Box >
                                        <ConfirmDateTime
                                            setForm={setForm}
                                            isSelectSlote={isSelectedSlote}
                                            booking={BookingDrawer}
                                        />
                                    </Box>
                                </Drawer>

                                {/* if user want to address than add address drawer  */}
                                <Drawer
                                    open={addAddress}
                                    anchor="right"
                                    sx={{
                                        display: { xs: "block", sm: "block" },
                                        "& .MuiDrawer-paper": {
                                            boxSizing: "border-box",
                                            width: { md: 580, xs: "100%" },
                                        },
                                    }}
                                >
                                    <Box>
                                        <AddAddressMap
                                            CompleteAddress={CompleteAddress}
                                            setForm={setForm}
                                            addAddress={setAddAddress}
                                        />
                                    </Box>
                                </Drawer>

                                {/* add address form  */}
                                <Drawer
                                    open={addressFrom}
                                    anchor="right"
                                    sx={{
                                        display: { xs: "block", sm: "block" },
                                        "& .MuiDrawer-paper": {
                                            boxSizing: "border-box",
                                            width: { md: 580, xs: "100%" },
                                        },
                                    }}
                                >
                                    <Box>
                                        <AddAddressForm
                                            setAddressMap={setAddAddress}
                                            setAddressForm={setAddressForm}
                                        />
                                    </Box>
                                </Drawer>

                                {/* booking information drawer  */}
                                <Drawer
                                    open={booking}
                                    anchor="right"
                                    sx={{
                                        display: { xs: "block", sm: "block" },
                                        "& .MuiDrawer-paper": {
                                            boxSizing: "border-box",
                                            width: { md: 580, xs: "100%" },
                                        },
                                    }}
                                >
                                    <Box >
                                        <BookingInfoDrawer
                                            setForm={setForm}
                                            setBooking={setBooking}
                                            setPromo={setPromo}
                                        />
                                    </Box>
                                </Drawer>

                                {/* promocode drawer  */}
                                <Drawer
                                    open={promo}
                                    anchor="right"
                                    sx={{
                                        display: { xs: "block", sm: "block" },
                                        "& .MuiDrawer-paper": {
                                            boxSizing: "border-box",
                                            width: { md: 580, xs: "100%" },
                                        },
                                    }}
                                >
                                    <Box >
                                        <Promocode setBooking={setBooking} setPromo={setPromo} />
                                    </Box>
                                </Drawer>



                                {/* SIde bar phone navigation */}
                                <Drawer open={open} onClose={() => handleClose(setOpen)}
                                    sx={{
                                        display: { xs: "block", sm: "block" },
                                        "& .MuiDrawer-paper": {
                                            boxSizing: "border-box",
                                            width: { md: "25%", xs: "100%" },
                                        },
                                    }}
                                >
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        padding={3}
                                        marginLeft={3}
                                        justifyContent={"center"}
                                        alignSelf={"center"}
                                        textAlign={"center"}
                                    >
                                        <Box>
                                            <IconButton aria-label="Close Button" onClick={() => handleClose(setOpen)}
                                                sx={{ my: 5 }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                        <PhoneNavigationLinks />
                                    </Box>
                                </Drawer>


                            </Box>

                            {/* =================eDemand-Setting================  */}
                            <Drawer
                                anchor="right"
                                open={openSetting}
                                onClose={handleCloseSetting}
                                sx={{
                                    display: { xs: "block", sm: "block" },
                                    "& .MuiDrawer-paper": {
                                        boxSizing: "border-box",
                                        width: { md: 580, xs: "100%" },
                                    },
                                }}
                            >
                                <EdemandSetting
                                    changeDark={changeDark}
                                    changeLight={changeLight}
                                    setOpenSetting={setOpenSetting}
                                    view={view}
                                    setView={setView}
                                />
                            </Drawer>
                        </StyledToolBar>
                    </Box>
                </Container>
            </AppBar>
            <ToastContainer />
        </Box>
    );
};

export default Navigation;
