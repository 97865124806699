// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
const initialData = localStorage.getItem("CartDetails") !== "undefined" ? JSON.parse(localStorage.getItem("CartDetails")) : {data: []}
const initialQty = JSON.parse(localStorage.getItem("qty"));
export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: initialData !== null ? initialData.data : null,
    qty: initialQty !== null ? initialQty : null,
  },
  reducers: {
    updateCartItems: (state, action) => {
      
      localStorage.setItem("CartDetails", JSON.stringify({data: action.payload}));
      state.cartItems = action.payload
    },
    updateQty: (state, action) => {
      state.qty = action.payload;
      localStorage.setItem("qty", JSON.stringify(action.payload));
    },
  },
});

export const { updateCartItems, updateQty } = cartSlice.actions;

export default cartSlice.reducer;
